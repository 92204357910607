import React from 'react';
import $ from 'jquery';

export default function CheckIn() {
    return (
        <div>
            <header className="page-header">
                <h1>Check In!</h1>
            </header>
            <article className="njf-article">
                <section>
                    Check in hier!
                    <Tickera></Tickera>
                </section>
            </article>
        </div>
    )
}

class Tickera extends React.Component {
    handleSubmit(event) {
        event.preventDefault();
        $.ajax({
            data: this.state,
            type: 'POST',
            url: './php/checkin.php',
            success: function (data) {
                $("#submitted").show();
            },
            error: function (xhr, status, err) {
                console.log(status, err);
            }
        })
    }

    render() {
        return(
            <article className="njf-article">
                <form onSubmit={this.handleSubmit.bind(this)} method="POST">
                    <input type="submit" className="btn btn-primary njf-submit-button" value="Verzenden"/>
                </form>
            </article>
        )
    }
}