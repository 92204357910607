import React from 'react';
import njfData from '../data/njf.json';
import triviaData from '../data/trivial_data.json';

export default function NJF() {
    return (
        <div>
            <header className="page-header">
                <h1>Het NJF</h1>
            </header>
            <NJFArticle article={njfData.NJF.nl} />
            <NextEdition />
        </div>
    )
}

class NextEdition extends React.Component {
    render() {
        const nextEdition = triviaData.FollowingEdition;
        return (
            <article className="njf-article">
                {/* <a href={nextEdition.websiteUrl}>Nederlands Jongleer Festival</a> */}
                <table>
                    <tbody className="nextNjfTable">
                        <tr>
                            <td>Plaats:</td>
                            <td>{nextEdition.place}</td>
                        </tr>
                        <tr>
                            <td>Adres:</td>
                            <td>{nextEdition.address}</td>
                        </tr>
                        <tr>
                            <td>Organisatie:</td>
                            <td>{nextEdition.organizer}</td>
                        </tr>
                        <tr>
                            <td>Website:</td>
                            <td><a href={nextEdition.websiteUrl}>Nederlands Jongleer Festival</a></td>
                        </tr>
                    </tbody>
                </table>

                <iframe className="maps" title="njfLocation"
                        src="https://maps.google.com/maps?output=embed"
                        frameBorder="0"
                        style={{border: 0}} allowFullScreen=""></iframe>
                {/*<iframe className="maps" title="njfLocation" src="https://maps.google.com/maps?q=Lekdijk%20Oost%2015A,%203961%20MB%20Wijk%20bij%20Duurstede&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0" style={{ border: 0 }} allowFullScreen=""></iframe>*/}
            </article>
        )
    }
}

class NJFArticle extends React.Component {
    render() {
        const article = this.props.article;
        const paragraphs = article.paragraphs.map((p) => <NJFParagraph key={p.id} paragraph={p} />);
        return (
            <article className="njf-article">{paragraphs}</article>
        )
    }
}

class NJFParagraph extends React.Component {
    render() {
        const paragraph = this.props.paragraph;
        const heading = paragraph.heading !== undefined ? <NJFHeader header={paragraph.heading} /> : "";
        const listitems = paragraph.items !== undefined ? <ul>{paragraph.items.map((i) => <NJFSubitem key={i.id} subitem={i} />)}</ul> : "";
        const text = paragraph.text !== undefined ? <NJFText text={paragraph.text} /> : "";
        return (
            <section key={paragraph.id}>{heading}{text}{listitems}</section>
        )
    }
}

class NJFSubitem extends React.Component {
    render() {
        const subitem = this.props.subitem;
        return (
            <li key={subitem.id}>{subitem.item}</li>
        )
    }
}

class NJFText extends React.Component {
    render() {
        const text = this.props.text;
        return (
            <p>{text}</p>
        )
    }
}

class NJFHeader extends React.Component {
    render() {
        const header = this.props.header;
        return (
            <h4>{header}</h4>
        )
    }
}
