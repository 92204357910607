import React from 'react';
import recordData from '../data/records.json';
import recordVrouwen from '../data/records_vrouwen.json';
import { ReCaptcha } from 'react-recaptcha-google';
// import Confetti from 'canvas-confetti';
import $ from 'jquery';
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';

export default function Records() {
    return (
        <div>
            <header className="page-header">
                <h1>NL Records Jongleren</h1>
                <p>{recordData.omschrijving}</p>
            </header>
            <RecordOverview/>
            <hr></hr>
            <RecordForm/>
            <br></br>
            <footer className="njf-article">{recordData.footnote}<a href="https://www.stichtingnjf.nl/contact">contact formulier</a>.
            </footer>
        </div>
    )
}

class RecordOverview extends React.Component {
    render() {
        const records = recordData.props.map((r) => <Record record={r}/>);
        const recordsVrouwen = recordVrouwen.props.map((r) => <Record record={r}/>);
        return (
            <article className="record-article">
                <h3>Records</h3>
                <div className="records">
                    <div className="record_general">
                        <h4><i>Algemeen</i></h4>
                        {records}
                    </div>
                    {/* Achter de naam de pronouns zetten*/}
                    <div className="record_women">
                        <h4><i>Vrouwen en Genderqueer</i></h4>
                        {recordsVrouwen}
                    </div>
                </div>
            </article>
        )
    }
}

class Record extends React.Component {
    render() {
        const title = this.props.record.title;
        const recordRows = this.props.record.records.map((r) => <RecordRow recordRow={r}/>);
        return (
            <div>
                {/*<Accordion>*/}
                {/*    <AccordionSummary>*/}
                        <h5>{title}</h5>
                    {/*</AccordionSummary>*/}
                    {/*<AccordionDetails>*/}
                        <table className="recordTable">
                            <tbody>
                            {recordRows}
                            </tbody>
                        </table>
                    {/*</AccordionDetails>*/}
                {/*</Accordion>*/}
            </div>
        )
    }
}

class RecordRow extends React.Component {
    render() {
        const recordRow = this.props.recordRow;
        const videoLinken = recordRow.video !== null ? recordRow.video.map((l) => <RecordVideo videoLink={l}/>) : null;
        return (
            <tr className="recordRow">
                <td className="recordFiller"></td>
                {/*<td className="recordAantal">{recordRow.aantal} {recordRow.prop}</td>*/}
                <td className="recordAantal">{recordRow.aantal}</td>
                <td className="recordRecordHouder">{recordRow.recordHouder}</td>
                <td className="recordRecord">{recordRow.record}</td>
                <td className="recordVideo">{videoLinken}</td>
                <td className="recordNotes">{recordRow.notes}</td>
            </tr>
        )
    }
}

class RecordVideo extends React.Component {
    render() {
        const videoLink = this.props.videoLink;
        return (
            <div>
                <a href={videoLink} target="_blank" rel="noopener noreferrer"><u>Video</u></a>
                <br></br>
            </div>
        )
    }
}

class RecordForm extends React.Component {
    constructor() {
        super();
        this.state = {
            props: '',
            aantal: 0,
            catchesDuur: '',
            email: '',
            videoLink: '',
            recaptchaResponse: '',
            errors: {},
            jongleurs: '',
            mailResponse: '',
            aantalJongleurs: 0
        };

        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.updateJongleurs = this.updateJongleurs.bind(this);
        this.getJongleurs = this.getJongleurs.bind(this);
    }

    updateJongleurs(name) {
        this.setState({
            jongleurs: name
        });
    }

    getJongleurs() {
        return this.state.jongleurs;
    }

    handleSubmit(event) {
        event.preventDefault();
        if (/^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(this.state.email)) {
            $.ajax({
                data: this.state,
                type: 'POST',
                url: './php/recordMail.php',
                success: function (data) {
                    $('#submitted').show();
                    // Confetti({particleCount: 200, spread: 120});
                },
                error: function (xhr, status, err) {
                    console.log(status, err);
                }
            })

            this.setState({
                jongleurs: '',
                email: '',
                props: '',
                aantal: 0,
                catchesDuur: '',
                videoLink: '',
                aantalJongleurs: 0,
                recaptchaResponse: '',
                errors: {},
                mailResponse: ''
            });

            window.grecaptcha.reset();
        } else {
            this.setState({
                errors: {'email': 'Het opgegeven emailadres is niet valide. Dit moet zijn: voorbeeld@example.com'},
                mailResponse: ''
            });
        }
    }

    componentDidMount() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        this.setState({recaptchaResponse: recaptchaToken});
    }

    render() {
        return (
            <article className="njf-article">
                <h4>Heb jij een supergoed nieuw record dat hier aan toegevoegd moet worden, laat het ons dan weten via onderstaand formulier!</h4>
                <form id="record-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                    <div className="recordGroup">
                        <div className="recordInput">
                            <label className="input-label">Jongleervoorwerp</label>
                            <select id="prop" className="text-field"
                                    value={this.state.props}
                                    onChange={(e) => this.setState({props: e.target.value})}>
                                <option value=""></option>
                                <option value="ballen">Ballen</option>
                                <option value="kegels">Kegels</option>
                                <option value="ringen">Ringen</option>
                            </select>
                        </div>
                        <div className="recordInput">
                            <label className="input-label">Aantal {this.state.props}</label>
                            <input type="number" id="aantal" className="text-field"
                                   value={this.state.aantal}
                                   onChange={(e) => this.setState({aantal: e.target.value})}></input>
                        </div>
                    </div>

                    <div className="recordGroup">
                        <div className="recordInput">
                            <label className="input-label">Duur / Aantal catches</label>
                            <input id="catches" placeholder="Bijv. 36:30 min" className="text-field"
                                   value={this.state.catchesDuur}
                                   onChange={(e) => this.setState({catchesDuur: e.target.value})}></input>
                        </div>

                        <div className="recordInput">
                            <label className="input-label">Link naar video</label>
                            <input id="videolink" placeholder="Youtube / Vimeo" className="text-field"
                                   value={this.state.videoLink}
                                   onChange={(e) => this.setState({videoLink: e.target.value})}></input>
                        </div>
                    </div>

                    <div className="recordGroup">
                        <div className="recordInput">
                            <label className="input-label">Aantal jongleurs</label>
                            <select id="aantalJongleurs" className="text-field"
                                    value={this.state.aantalJongleurs}
                                    onChange={(e) => this.setState({aantalJongleurs: e.target.value})}>
                                <option value="" hidden></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </select>
                        </div>

                        <div className="recordInput">
                            <label className="input-label">E-mail</label>
                            <input
                                id="email"
                                placeholder="Bijv. info@stichtingnjf.nl"
                                className="text-field"
                                value={this.state.email}
                                onChange={(e) => this.setState({email: e.target.value})}
                                required>
                            </input>
                        </div>
                    </div>

                    <Jongleurs handler={this.updateJongleurs} jongleurs={this.state.jongleurs}/>

                    <ReCaptcha
                        ref={(el) => {
                            this.captchaDemo = el;
                        }}
                        size="normal"
                        render="explicit"
                        sitekey="6Le8bHgUAAAAAIPsyUp6bFj4ijtPfWnc-Zf2P6nm"
                        onloadCallback={this.onLoadRecaptcha}
                        verifyCallback={this.verifyCallback}
                    />

                    <input type="submit" className="btn btn-primary njf-submit-button" value="Verzenden"></input>
                </form>

                <h3 id="submitted" style={{display: 'none', 'margin-top': '10px'}}>Record verzonden!</h3>

                <span style={{color: 'red'}}>{this.state.errors['email']}</span>
                {/* <span className="mail-sent-label">{this.state.mailResponse}</span> */}
            </article>
        )
    }
}

class Jongleurs extends React.Component {
    render() {
        return (
            <div>
                <label className="input-label">Naam jongleur(s)</label>
                <input id="naam" placeholder="Bijv. Jan Janssen" className="text-field"
                       value={this.props.jongleurs}
                       onChange={(e) => this.props.handler(e.target.value)}></input>
            </div>
        )
    }
}
