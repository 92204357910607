import React, {Component} from 'react';
// import { ReCaptcha } from 'react-recaptcha-v3'
import $ from 'jquery';
import {ReCaptcha} from 'react-recaptcha-google';

class Contact extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            name: '',
            email: '',
            comments: '',
            recaptchaResponse: '',
            mailResponse: '',
            errors: {}
        };
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        if (/^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(this.state.email)) {
            $.ajax({
                data: this.state,
                type: 'POST',
                url: './php/email.php',
                success: function (data) {
                    $('#submitted').show();
                },
                error: function (xhr, status, err) {
                    console.log(status, err);
                }
            })

            this.setState({
                name: '',
                email: '',
                comments: '',
                recaptchaResponse: '',
                errors: {}
            });
            window.grecaptcha.reset();
        } else {
            this.setState({
                errors: {'email': 'Het opgegeven emailadres is niet valide. Dit moet zijn: voorbeeld@example.com'},
                mailResponse: ''
            });
        }
    }

    componentDidMount() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        this.setState({recaptchaResponse: recaptchaToken});
    }

    render() {

        return (
            <div>
                <header className="page-header">
                    <h1>Contact</h1>
                </header>
                <article className="njf-article">
                    <form id="contact_form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                        <label className="input-label">Naam</label>
                        <input
                            id="name"
                            placeholder="Bijv. Jan Janssen"
                            className="text-field"
                            value={this.state.name}
                            onChange={(e) => this.setState({name: e.target.value})}
                            required>
                        </input>
                        <label className="input-label">E-mail</label>
                        <input
                            id="email"
                            placeholder="Bijv. contact@stichtingnjf.nl"
                            className="text-field"
                            value={this.state.email}
                            onChange={(e) => this.setState({email: e.target.value})}
                            required>
                        </input>
                        <label className="input-label">Opmerkingen</label>
                        <textarea
                            id="comments"
                            placeholder="Opmerkingen/aanmerkingen"
                            className="text-field"
                            value={this.state.comments}
                            onChange={(e) => this.setState({comments: e.target.value})}
                            required>
                        </textarea>
                        <ReCaptcha
                            ref={(el) => {
                                this.captchaDemo = el;
                            }}
                            size="normal"
                            render="explicit"
                            sitekey="6Le8bHgUAAAAAIPsyUp6bFj4ijtPfWnc-Zf2P6nm"
                            onloadCallback={this.onLoadRecaptcha}
                            verifyCallback={this.verifyCallback}
                        />
                        <input type="submit" className="btn btn-primary njf-submit-button" value="Verzenden"></input>
                    </form>

                    <h3 id="submitted" style={{display: 'none', 'margin-top': '10px'}}>Formulier verzonden!</h3>

                    <span style={{color: 'red'}}>{this.state.errors['email']}</span>
                    {/* <span className="mail-sent-label">{this.state.mailResponse}</span> */}
                </article>
            </div>
        );
    }
}

export default Contact;
