import React from 'react';
import notFound from '../assets/images/404.png';

export default function NotFound() {
    return(
        <div>
            <header className="page-header">
                <h1>404: siteswap not found</h1>
            </header>
            <article className="njf-article">
                <h3>
                Deze pagina bestaat (nog) niet op onze website, kijk snel op een van de andere pagina's voor meer toffe informatie! 
                </h3>
            </article>
            <article className="notFoundImage">
                <img src={notFound}></img>
            </article>
        </div>
    )
}