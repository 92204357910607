import React from 'react';
import organiseren from '../data/organiseren.json';

export default function Organiseren() {
    return (
        <div>
            <header className="page-header">
                <h1>Hoe organiseer ik een festival?</h1>
            </header>
            <NJFArticle article={organiseren.organiseren.nl}/>
        </div>
    )
}

class NJFArticle extends React.Component {
    render() {
        const article = this.props.article;
        const paragraphs = article.paragraphs.map((p) => <NJFParagraph key={p.id} paragraph={p}/>);
        return (
            <div className="njf-article">
                <article>{paragraphs}</article>
                <article>
                    <section>
                        <a href="/data/20240507_Handleiding_NJF_organiseren_v2.4.pdf" download>Handleiding NJF organiseren v2.4</a>
                        <p>Laatste update: 07 mei 2024</p>
                        <p>Heb je verder nog vragen, neem dan zeker <a href="https://www.stichtingnjf.nl/contact">contact</a> op zodat we je op weg kunnen helpen.
                        </p>
                    </section>
                </article>
            </div>
        )
    }
}

class NJFParagraph extends React.Component {
    render() {
        const paragraph = this.props.paragraph;
        const heading = paragraph.heading !== undefined ? <NJFHeader header={paragraph.heading}/> : '';
        const listitems = paragraph.items !== undefined ?
            <ul>{paragraph.items.map((i) => <NJFSubitem key={i.id} subitem={i}/>)}</ul> : '';
        const text = paragraph.text !== undefined ? <NJFText text={paragraph.text}/> : '';
        return (
            <section key={paragraph.id}>{heading}{text}{listitems}</section>
        )
    }
}

class NJFSubitem extends React.Component {
    render() {
        const subitem = this.props.subitem;
        return (
            <li key={subitem.id}>{subitem.item}</li>
        )
    }
}

class NJFText extends React.Component {
    render() {
        const text = this.props.text;
        return (
            <p>{text}</p>
        )
    }
}

class NJFHeader extends React.Component {
    render() {
        const header = this.props.header;
        return (
            <h4>{header}</h4>
        )
    }
}
