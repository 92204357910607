import React, { Component } from 'react';

class Diabolo extends Component {
    render() {
        return (
            <div className="juggler-container">
                <div className="diabolo">
                    <div className="diabolo-rope"></div>
                    <div className="diabolo-diabolo">
                        <div className="diabolo-inner">
                            <div className="diabolo-spoke diabolo-spoke-1"></div>
                            <div className="diabolo-spoke diabolo-spoke-2"></div>
                            <div className="diabolo-spoke diabolo-spoke-3"></div>
                            <div className="diabolo-spoke diabolo-spoke-4"></div>
                            <div className="diabolo-axle">
                                <div className="diabolo-pin"></div>
                            </div>
                        </div>
                    </div>
                    <div className="diabolo-sticks">
                        <div className="diabolo-stick-left">
                            <div className="diabolo-stick-top"></div>
                        </div>
                        <div className="diabolo-stick-right">
                            <div className="diabolo-stick-top"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Diabolo;