import React from 'react';
import CanvasJSReact from '../assets/canvasjs/canvasjs.react';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function Archive(data) {
    const editions = data.editions;
    return (
        <div>
            <header className="page-header">
                <h1>Archief</h1>
            </header>
            <article className="njf-article">
                <section className="njf-archive-media-section">Bekijk de foto's van voorgaande edities <a href="https://stichtingnjf.nl/photo" rel="noopener noreferrer">hier</a>!
                </section>
                <section>
                    <NJFHistoryChart data={editions}/>
                </section>
                <section>
                    <NJFHistoryTable data={editions}/>
                </section>
            </article>
        </div>
    )
}

class NJFHistoryChart extends React.Component {
    render() {
        const data = this.props.data;

        const points = [];
        data.forEach(edition => {
            let point =
                {
                    x: new Date(edition.year, 0),
                    y: edition.total_visitors === '-1' ? 0 : parseInt(edition.total_visitors),
                };
            points.push(point);
        })

        const chartData = {
            animationEnabled: true,
            exportEnabled: false,
            theme: 'light2',
            toolTip: {
                borderColor: 'black'
            },
            axisY: {
                title: 'Bezoekers'
            },
            axisX: {
                labelAngle: 30
            },
            data: [{
                xValueFormatString: 'YYYY',
                type: 'spline',
                dataPoints: points
            }]
        }

        return (
            <div className="njf-edition-chart">
                <h4>NJF bezoekersaantallen</h4>
                <CanvasJSChart className="edition-canvas-chart" options={chartData}/>
            </div>
        )
    }
}

class NJFHistoryTable extends React.Component {
    render() {
        let nrEdition = this.props.data.length;
        const editionRows = this.props.data.map((e) => {
            if (e.year !== '2021')
                nrEdition--;
            return <EditionRow edition={e} nrEdition={nrEdition}/>
        });
        return (
            <div>
                <h4>Overzicht van NJF edities</h4>
                <table className="editionTable">
                    <thead>
                    <tr className="editionRow">
                        <th className="editionNumber">Editie</th>
                        <th className="editionLocation">Locatie</th>
                        <th className="editionYear">Jaar</th>
                        <th className="editionVisitors">Totaal bezoekers</th>
                        <th className="editionVisitors">Voor- inschrijving</th>
                        <th className="editionVisitors">Bezoekers donderdag</th>
                        <th className="editionVisitors">Bezoekers vrijdag</th>
                        <th className="editionVisitors">Bezoekers zaterdag</th>
                        <th className="editionVisitors">Bezoekers zondag</th>
                        <th className="editionVisitors">Bezoekers volledig festival</th>
                        <th className="editionVisitors">Bezoekers jeugd</th>
                    </tr>
                    </thead>
                    <tbody>
                    {editionRows}
                    </tbody>
                </table>
                <i>* Online editie in verband met de Covid Pandemie.</i>
                <br></br>
                <br></br>
            </div>
        )
    }
}

class EditionRow extends React.Component {
    render() {
        const edition = this.props.edition;
        const nrEdition = this.props.nrEdition;
        return (
            <tr className="editionRow">
                <td className="editionNumber">{edition.year === '2021' ? '-' : nrEdition}</td>
                <td className="editionLocation">{edition.location}</td>
                <td className="editionYear">{edition.year}</td>
                <td className="editionVisitors">{edition.total_visitors === '-1' ? '-' : edition.total_visitors}</td>
                <td className="editionVisitors">{edition.pre_registration === '-1' ? '-' : edition.pre_registration}</td>
                <td className="editionVisitors">{edition.visitors_thursday === '-1' ? '-' : edition.visitors_thursday}</td>
                <td className="editionVisitors">{edition.visitors_friday === '-1' ? '-' : edition.visitors_friday}</td>
                <td className="editionVisitors">{edition.visitors_saturday === '-1' ? '-' : edition.visitors_saturday}</td>
                <td className="editionVisitors">{edition.visitors_sunday === '-1' ? '-' : edition.visitors_sunday}</td>
                <td className="editionVisitors">{edition.visitors_full_festival === '-1' ? '-' : edition.visitors_full_festival}</td>
                <td className="editionVisitors">{edition.visitors_youth === '-1' ? '-' : edition.visitors_youth}</td>
            </tr>
        )
    }
}
