import React, { Suspense, Component } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import './App.css';
import './assets/stichtingnjf.css';
import './assets/animations.css';
import trivialData from './data/trivial_data.json';
import { loadReCaptcha } from 'react-recaptcha-google';
import anime from 'animejs/lib/anime.es.js';

import Home from './components/home';
import About from './components/about';
import Purpose from './components/purpose';
import Initiative from './components/initiative';
import NJF from './components/njf';
import Archive from './components/archive';
import Contact from './components/contact';
import Footer from './components/footer';
import Records from './components/records';
import NotFound from './components/404';
import logo from './assets/images/njf_logo.png';
import CheckIn from './components/check-in';
import Organiseren from './components/organiseren';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      easterEgg: 0,
      editions: trivialData.NJF.editions.reverse()
    };
    this.changeEasterEgg = this.changeEasterEgg.bind(this);
  }

  changeEasterEgg(number) {
    this.setState({ easterEgg: number });
  }

  render() {
    return (
      <Router>
        <div className="container-fluid">
          <Navbar className="navbar" expand="lg">
            <Navbar.Brand href="/">
              <img
                src={logo}
                width="300"
                height="150"
                className="d-inline-block align-top"
                alt="Stichting NJF"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Link to="/" className="nav-link" onClick={() => this.changeEasterEgg(1)}>Home</Link>
                <Link to="/over" className="nav-link" onClick={() => this.changeEasterEgg(1)}>Over</Link>
                <Link to="/doel" className="nav-link" onClick={() => this.changeEasterEgg(2)}>Doel</Link>
                <Link to="/initiatiefnemers" className="nav-link" onClick={() => this.changeEasterEgg(3)}>Bestuur</Link>
                <Link to="/njf" className="nav-link" onClick={() => this.changeEasterEgg(0)}>NJF</Link>
                <Link to="/organiseren" className="nav-link" onClick={() => this.changeEasterEgg(0)}>Organiseren</Link>
                <Link to="/archief" className="nav-link" onClick={() => this.changeEasterEgg(1)}>Archief</Link>
                <Link to="/contact" className="nav-link" onClick={() => this.changeEasterEgg(2)}>Contact</Link>
                <Link to="/records" className="nav-link" onClick={() => this.changeEasterEgg(0)}>Records</Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div className="njf-grid">
            <div className="njf-grid-left">
            </div>
            <div className="njf-grid-center">
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/over" component={About} />
                  <Route exact path="/doel" component={Purpose} />
                  <Route exact path="/initiatiefnemers" component={Initiative} />
                  <Route exact path="/njf" component={NJF} />
                  <Route exact path="/archief" component={() => <Archive editions={this.state.editions} />} />
                  <Route exact path="/organiseren" component={Organiseren} />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/records" component={Records} />
                  <Route exact path="/checkin" component={CheckIn} />
                  <Route path='/mail' component={() => { 
                      window.location.href = 'https://stichtingnjf.nl/mail'; 
                      return null;
                  }}/>
                  <Route path='/photo' component={() => { 
                      window.location.href = 'https://stichtingnjf.nl/photo'; 
                      return null;
                  }}/>
                  <Route path='/login' component={() => { 
                      window.location.href = 'https://stichtingnjf.nl/login'; 
                      return null;
                  }}/>
                  <Route path="*" component={NotFound}>
                  </Route>
                  {/* /?_task=mail&_mbox=INBOX */}
                </Switch>
              </Suspense>
            </div>
            <div className="njf-grid-right">
            </div>
          </div>
          <Footer easterEggNum={this.state.easterEgg} />

          {/* <div id="animatebubbles" class="container">
            <div class="bubblewrapper">
              <div class="bubblepath">
                  <div id="bubble1" class="bubble">
                  </div>
              </div>
              <div class="bubblepath">
                  <div id="bubble2" class="bubble"> 
                  </div>
              </div>
              <div class="bubblepath">
                  <div id="bubble3" class="bubble">
                  </div>
              </div>
              <div class="bubblepath">
                  <div id="bubble4" class="bubble">
                  </div>
              </div>
            </div>
          </div> */}

        </div>
      </Router>
    );
  }

  componentDidMount() {
    this.setState({ easterEgg: this.changeEasterEgg() });
    loadReCaptcha();
    // animation();
  }
}

function animation()
{
  // var object = document.querySelector('.sphere-animation');
  var timelineParameters = anime.timeline({
    loop: false
  });

  timelineParameters
  .add({
      targets: ['#bubble1', '#bubble2', '#bubble3', '#bubble4'],
      translateX: 0,
      translateY: 0,
      duration: 100
      })
  .add({
      targets: '#bubble1',
      translateX: [ { value: randomMovement  }, { value: randomMovement }, { value: randomMovement } ],
      translateY: [ { value: +200  }, { value: +400 }, { value: +600 } ],
      opacity: [ {value: 0.5 }, { value: 0 }],
      easing: 'linear',
      duration: randomSpeed
  })
  .add({
      targets: '#bubble2',
      translateX: [ { value: randomMovement }, { value: randomMovement }, { value: randomMovement } ],
      translateY: [ { value: +200 }, { value: +400 }, { value: +600 } ],
      opacity: [ { value: 0.8 }, { value: 0 }],
      easing: 'linear',
      duration: randomSpeed,
      offset: 100
  })
  .add({
      targets: '#bubble3',
      translateX: [ { value: randomMovement }, { value: randomMovement }, { value: randomMovement } ],
      translateY: [ { value: +200 }, { value: +400 }, { value: +600 } ],
      opacity: [ { value: 0.3 }, { value: 0 }],
      easing: 'linear',
      duration: randomSpeed,
      offset: 0
  })
  .add({
      targets: '#bubble4',
      translateX: [ { value: randomMovement }, { value: randomMovement }, { value: randomMovement } ],
      translateY: [ { value: +200 }, { value: +400 }, { value: +600 } ],
      opacity: [ { value: 0.8 }, { value: 0 }],
      easing: 'linear',
      duration: randomSpeed,
      offset: 300
  });
  timelineParameters.complete = function() {animation();};
}

var randomMovement = function() {
  return anime.random(-20, 20) + 'rem'
};

var randomSpeed = function() {
  return anime.random(1000, 5000) + 'rem'
};

export default App;
