import React, { Component } from 'react';

class Devilstick extends Component{
    render() {
        return (
            <div className="juggler-container">
                <div className="devilstick">
                <div className="devilstick-right-stick">
                    {/* <div className="top"></div>
                    <div className="front"></div>
                    <div className="side"></div> */}
                </div>
                <div className="devilstick-left-stick"></div>
                <div className="devilstick-flowerstick">
                    <div className="devilstick-flowerstick-top"></div>
                    <div className="devilstick-flowerstick-bottom"></div>
                    <div className="line-top"></div>
                    <div className="line-center"></div>
                    <div className="line-bottom"></div>
                </div>
                </div>
            </div>
        )
    }
}

export default Devilstick;