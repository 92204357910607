import React, { Component } from 'react';

class Juggler extends Component {
    render() {
        return (
            <div className="juggler-container">
                <div className="juggler">
                    <div id="1" className="juggle-ball juggle-ball-left juggle-ball-1-left"></div>
                    <div id="2" className="juggle-ball juggle-ball-right juggle-ball-1-right"></div>
                    <div id="3" className="juggle-ball juggle-ball-left juggle-ball-2-left"></div>
                    <div id="4" className="juggle-ball juggle-ball-right juggle-ball-2-right"></div>
                    <div id="5" className="juggle-ball juggle-ball-left juggle-ball-3-left"></div>
                    <div id="6" className="juggle-ball juggle-ball-right juggle-ball-3-right"></div>
                    <div id="7" className="juggle-ball juggle-ball-left juggle-ball-4-left"></div>
                    <div id="8" className="juggle-ball juggle-ball-right juggle-ball-4-right"></div>
                    <div id="9" className="juggle-ball juggle-ball-left juggle-ball-5-left"></div>
                    <div id="10" className="juggle-ball juggle-ball-right juggle-ball-5-right"></div>
                    <div className="juggler-leg"></div>
                    <div className="juggler-body"></div>
                    <div className="juggler-arm-left"></div>
                    <div id="la" className="juggler-arm-left-lower"></div>
                    <div className="juggler-arm-right"></div>
                    <div id="ra" className="juggler-arm-right-lower"></div>
                    <div className="juggler-head"></div>
                </div>
            </div>
        )
    }
}

export default Juggler;