import React from 'react';
import aboutData from '../data/about.json';

export default function About() {
    const listitems = aboutData.About.nl.items.map((aboutItem) => {
        let subitems;
        if (aboutItem.sub_items !== undefined) {
            subitems = aboutItem.sub_items.map((sub) => {
                return <li key={sub.sub_item}>{sub.sub_item}</li>;
            });
        }
        if (subitems !== undefined) {
            return <li key={aboutItem}>{aboutItem.item}<ul>{subitems}</ul></li>;
        }
        else {
            if(aboutItem.item.includes("handboek")) {
                return <li key={aboutItem.item}>{aboutItem.item}
                <a href="/data/20240507_Handleiding_NJF_organiseren_v2.4.pdf" download>hier.</a>
                </li>;
            }else{
                return <li key={aboutItem.item}>{aboutItem.item}</li>
            }
        }
    });

    return (
        <div>
            <header className="page-header">
                <h1>Over Stichting NJF</h1>
            </header>
            <section className="njf-article">
                <p>{aboutData.About.nl.text}</p>
                <ul>{listitems}</ul>
            </section>
        </div>
    )
}
