import React from 'react';
import purposeData from '../data/purpose.json'

export default function Purpose() {
    return (
        <div>
            <header className="page-header">
                <h1>Doel</h1>
            </header>
            <article className="njf-article">
                <p>
                    {purposeData.Purpose.nl.split('\n').map((item, key) =>
                        <span key={key}>{item}<br /></span>
                    )}
                </p>
            </article>

        </div>
    )
}