import React, { Component } from 'react';

class Unicycle extends Component {
    componentDidMount() {
        let angle = 360 / document.querySelectorAll(".unicycle-wheel-spoke").length;
        let i = 0;
        while (i < 361) {
            // eslint-disable-next-line no-loop-func
            document.querySelectorAll(".unicycle-wheel-spoke").forEach(element => {
                element.style.transform = "rotate(" + i + "deg)";
                i += angle;
            });
        }
    }

    render() {
        return (
            <div className="juggler-container">
                <div className="unicycle">
                    <div className="unicycle-wheel">
                        <div className="unicycle-wheel-spokes">
                            <li className="unicycle-wheel-spoke"></li>
                            <li className="unicycle-wheel-spoke"></li>
                            <li className="unicycle-wheel-spoke"></li>
                            <li className="unicycle-wheel-spoke"></li>
                            <li className="unicycle-wheel-spoke"></li>
                        </div>
                    </div>
                    <div className="unicycle-wheel-inner"></div>
                    <div className="unicycle-seat"></div>
                    <div className="unicycle-pen"></div>
                    <div className="unicycle-pedals">
                        <div className="unicycle-pedal"></div>
                        <div className="unicycle-pedal-pen"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Unicycle;