import React from 'react';
import homeData from '../data/index.json';

export default function Home() {
    const homeText = homeData.Index.nl.paragraphs.map((item, key) => {
        if (item.items !== undefined) {
            const subitems = item.items.map((subitem, subkey) => {
                if(subitem.link.url !== ""){
                    if(subitem.link.type === "download"){
                        return <li key={subkey}><a href={subitem.link.url} download>{subitem.item}</a></li>;
                    }else{
                        return <li key={subkey}><a href={subitem.link.url}>{subitem.item}</a></li>;
                    }
                }else {
                    return <li key={subkey}>{subitem.item}</li>;
                }
            });
            return <div key={key}><p>{item.text}</p><ul>{subitems}</ul></div>;
        }
        return <p key={key}>{item.text}</p>;
    });

    return (
        <div>
            <header className="page-header">
                <h1>Stichting Nederlands Jongleerfestival</h1>
            </header>
            <article className="njf-article">
                <section>
                    {homeText}
                </section>
            </article>
        </div>
    )
}